<script lang="ts" setup>
const tabs = [
  { label: 'Active RFQs', to: '/active-rfqs' },
  { label: 'Closed RFQs', to: '/closed-rfqs' },
]

const route = useRoute()
const tab = computed(() => {
  return tabs.findIndex(t => t.to === route.path)
})
const { data } = useCoreData('/liquidity/kpis')

const kpis = computed(() => {
  return [{
    title: 'Active in Forgd Since',
    value: data.value?.createdAt,
    format: 'date',
  }, {
    title: 'My Current Active Quotes',
    value: data.value?.activeQuotesQty,
    format: 'number:short',
  }, {
    title: 'Lifetime Quotes Submitted',
    value: data.value?.totalQuotesSubmitted,
    format: 'number:short',
  }, {
    title: 'Lifetime Quotes Accepted',
    value: data.value?.totalQuotesAccepted,
    format: 'number:short',
  }, {
    title: 'Active Projects',
    value: data.value?.activeProjectsQty,
    format: 'number:short',
  }]
})

const uiHeader = {
  text: 'text-sm',
}
</script>

<template>
  <div data-layout="home">
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard">
      <div class="flex flex-col gap-10">
        <UiHeader
          type="page"
          title="Welcome to Forgd’s Liquidity Portal"
          text="Track project’s RFQs, submit and evaluate competitors proposals, and engage with projects to increase your portfolio."
          :ui="uiHeader"
        >
          <template #action>
            <AcademySlideoverButton path="/_liquidity/how-it-works">
              How Our Process Works
            </AcademySlideoverButton>
          </template>
        </UiHeader>
        <UiStats :data="kpis" />
        <UiTabs :model-value="tab" :items="tabs" />
        <div>
          <slot />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
